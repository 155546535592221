<script>

import LeftNavigation from "@/components/LeftNavigation.vue";

export default {
  components: {LeftNavigation}
}
</script>

<template>
  <div class="Order_list">
    <LeftNavigation :menu="[
      {name: 'order_all', ti: '全部订单'},
      {name: 'order_transfer', ti: '换车记录'},
      {name: 'order_renewal', ti: '续租提示'},
    ]"></LeftNavigation>
    <section>
      <router-view></router-view>
    </section>
  </div>
</template>

<style scoped lang="less">
.Order_list{
  display: flex;
  height: 100%;
  overflow: hidden;
}
section{
  box-sizing: border-box;
  width: calc(100% - 200px);
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}
</style>
